import { Heading1, Heading2 } from '@roc-digital/ui-web';
import { useLayoutEffect } from 'react';
import { isMobile } from 'react-device-detect';

type Props = {
  url?: string;
  title?: string;
  description?: string;
};

const ShareThis = ({ url, title, description = 'MXM Subscription' }: Props) => {
  const loadShareButtons = () => {
    let shareUrl = url ? url : window.location.href;
    /* @ts-ignore */
    window.__sharethis__.load('inline-share-buttons', {
      alignment: 'left',
      id: 'my-inline-buttons',
      enabled: true,
      font_size: 11,
      // padding: 8,
      radius: 8,
      networks: ['messenger', 'email', 'twitter', 'facebook', 'pinterest', 'sms', 'wechat', 'sharethis'],
      // size: 32,
      show_mobile_buttons: isMobile,
      spacing: 4,
      url: shareUrl,
      title: title ? title + ' ~ MxM News' : 'MxM News',
      language: "en",
      image: "https://18955-presscdn-pagely.netdna-ssl.com/wp-content/uploads/2016/12/ShareThisLogo2x.png", // useful for pinterest sharing buttons
      description: description,
      username: "mxmnews"
    });
  }

  useLayoutEffect(() =>{
    loadShareButtons();
  }, []);

  return (
    <div className="p-8 dark:bg-surface-dark" onLoad={() => loadShareButtons()}>
      <Heading1 className="font-[Chivo] !font-bold text-3xl dark:text-white mb-8">Share This Content</Heading1>
      <div id="my-inline-buttons" className="sharethis-inline-share-buttons inline-share-buttons"></div>
    </div>
  );
};

export default ShareThis;
